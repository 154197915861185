import ar from './translations/ar.json';
import cs from './translations/cs.json';
import de from './translations/de.json';
import el from './translations/el.json';
import enGB from './translations/en-GB.json';
import enUS from './translations/en-US.json';
import es from './translations/es.json';
import hr from './translations/hr.json';
import iw from './translations/iw.json';
import pl from './translations/pl.json';
import pt from './translations/pt.json';

export const resources = {
    ar: {
        translation: ar,
    },
    cs: {
        translation: cs,
    },
    de: {
        translation: de,
    },
    el: {
        translation: el,
    },
    'en-GB': {
        translation: enGB,
    },
    'en-US': {
        translation: enUS,
    },
    es: {
        translation: es,
    },
    iw: {
        translation: iw,
    },
    hr: {
        translation: hr,
    },
    pl: {
        translation: pl,
    },
    pt: {
        translation: pt,
    },
};
