import * as en_US from '../i18n/locales/translations/en-US.json';
import * as en_GB from '../i18n/locales/translations/en-GB.json';
import * as ar from '../i18n/locales/translations/ar.json';
import * as cs from '../i18n/locales/translations/cs.json';
import * as de from '../i18n/locales/translations/de.json';
import * as el from '../i18n/locales/translations/el.json';
import * as es from '../i18n/locales/translations/es.json';
import * as hr from '../i18n/locales/translations/hr.json';
import * as iw from '../i18n/locales/translations/iw.json';
import * as pl from '../i18n/locales/translations/pl.json';
import * as pt from '../i18n/locales/translations/pt.json';

export default class AnI18NextLibHelper {
    public static loadLang = (lang: string) => {
        let anI18Nextlib: any = en_US;

        switch (lang) {
            case 'ar':
                anI18Nextlib = ar;
                break;
            case 'cs':
                anI18Nextlib = cs;
                break;
            case 'de':
                anI18Nextlib = de;
                break;
            case 'en-US':
                anI18Nextlib = en_US;
                break;
            case 'en-GB':
                anI18Nextlib = en_GB;
                break;
            case 'el':
                anI18Nextlib = el;
                break;
            case 'es':
                anI18Nextlib = es;
                break;
            case 'hr':
                anI18Nextlib = hr;
                break;
            case 'iw':
                anI18Nextlib = iw;
                break;
            case 'pl':
                anI18Nextlib = pl;
                break;
            case 'pt':
                anI18Nextlib = pt;
                break;
            default:
                anI18Nextlib = en_US;
                break;
        }

        return anI18Nextlib['default'];
    };

    private static translate = (dict: any, keys: string[]): string => {
        let output = '';

        if (dict && typeof dict === 'object' && keys?.length > 0) {
            const val = dict[keys[0]];

            if (val && typeof val === 'object' && keys?.length > 1) {
                output = AnI18NextLibHelper.translate(val, keys.slice(1));
            } else {
                output = val;
            }
        }

        return output;
    };

    public static Translate = (dictOrig: any, key: string, subs?: any): string => {
        let output = AnI18NextLibHelper.translate(dictOrig ?? AnI18NextLibHelper.loadLang(''), key?.split('.'));

        if (output && subs) {
            for (const fld in subs) {
                // Note:  replaceAll() break PR pipeline
                // output = output.replaceAll(token, `${subs[fld]}`);

                const token = `{{${fld}}}`;

                while (output.indexOf(token) > -1) {
                    output = output.replace(token, `${subs[fld]}`);
                }
            }
        }

        return output;
    };
}
