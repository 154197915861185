import { useEffect, useState } from 'react';

import { isAppMarketTranslationTextRightToLeft } from 'src/helpers/getAppMarketTranslation';

export const RIGHT_TO_LEFT = 'rtl';

export const LEFT_TO_RIGHT = 'ltr';

export const useDocumentOrientation = () => {
    const [documentOrientation, setDocumentOrientation] = useState<'ltr' | 'rtl'>(LEFT_TO_RIGHT);

    useEffect(() => {
        if (isAppMarketTranslationTextRightToLeft()) {
            setDocumentOrientation(RIGHT_TO_LEFT);
        }
    }, []);

    return documentOrientation;
};
